<template>
  <div class="wrap">
    <!--    <div class="header">
          售后消息
        </div>-->
    <div class="loading" v-show="loading">
      <van-loading size="36px" vertical >
        <template v-if="isRefresh">
          <div>正在获取所有最新消息，请稍等……</div>
          <div>由于美客多接口限制，每次更新可能需要较长时间，感谢你的耐心等待</div>
        </template>
        <template v-else>
          <div>加载中...</div>
        </template>
      </van-loading>
    </div>
    <div class="content-wrap-box" :class="{'show-uploader': uploaderShow}" ref="box" v-show="!loading">
      <div class="content-wrap">
        <div class="order">
          <div class="other">
            <div class="name">{{ message.customerName }}</div>
            <div class="right">
              <div>
                <span class="order-id">#{{ message.orderId }},</span> <span class="date">{{ message.orderTime }}</span>
              </div>
              <div class="shipping-info" v-if="message.shippingId">
                <span class="shipping-id">{{ message.shippingId }}, </span> <span class="shipping-status">{{ message.shippingInfo ? message.shippingInfo.shippingStatusText : '-' }}</span>
              </div>
            </div>
          </div>
          <div class="order-list">
            <div class="item" v-for="order in message.orders" :key="order.orderId">
              <div class="goods-list">
                <div class="goods" v-for="goods in order.goods" :key="goods.goodsId">
                  <div class="image" @click="toGoodsOriginPage(goods.goodsId)">
                    <img :src="goods.image" alt="">
                  </div>
                  <div class="info">
                    <div class="title">{{ goods.attrName ? goods.attrName : goods.title }}</div>
                    <div class="id">
                      <span>#{{ goods.goodsId }}</span>
                      <!--                      <span class="space" v-if="goods.attrName">|</span>-->
                      <!--                      <span class="attr-name" v-if="goods.attrName">{{ goods.attrName }}</span>-->
                    </div>
                    <div class="num">
                      数量：{{ goods.num }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="amount-wrap" v-show="message.kinds > 1">
                <div class="line">售：${{ order.totalAmount }}</div>
                <div class="line">结：${{ order.settleAmount }}</div>
              </div>
            </div>
          </div>
          <div class="amount-data">
            <div class="amount">销售金额：$ {{ message.totalAmount }}</div>
            <div class="amount">结算金额：$ {{ message.settleAmount }}</div>
          </div>
        </div>
        <div class="chat-list">
          <div class="item" v-for="(item, k) in chatList" :key="item.messageId" :class="{item1: item.type === 2, item2: item.type === 1}">
            <div class="avatar" v-if="item.type === 2">
              {{ message.customerNameAbbr }}
            </div>
            <div class="avatar" v-if="item.type === 1">
              <van-icon class="wx-avatar" :name="userInfo.avatar" />
            </div>
            <div class="info">
              <div class="box box-text first" v-if="!isTextEmpty(item.text)" :class="{'blue': item.type === 1}">
                <div class="text" v-html="item.textFormat"></div>
                <div class="date">{{ item.messageDate.substr(0, 16) }}</div>
              </div>
              <div class="box box-text-translated" v-if="!isTextEmpty(item.textTranslated)" :class="{'blue': item.type === 1 && isTextEmpty(item.text)}">
                <div class="text" v-html="item.textTranslatedFormat"></div>
                <div class="ext" v-if="item.type === 2 || (item.type === 1 && !isTextEmpty(item.text))">
                  由 MercadoLibre 提供翻译支持
                </div>
                <div class="date" v-if="isTextEmpty(item.text)">{{ item.messageDate.substr(0, 16) }}</div>
              </div>
              <div class="" v-if="item.files.length">
                <div class="file-wrap" v-for="(file, kk) in item.files" :key="file.file" >
                  <div class="image-file" v-if="isImageFile(file)" @click="onClickFile(file, k, kk)">
                    <img :src="file.file" alt="" >
                  </div>
                  <a v-else class="other-file" @click="onClickFile(file)">
                    <img :src="require('@/assets/images/chat-file-logo.png')" alt="" class="icon">
                    <div class="name">
                      {{ file.originalFilename }}
                    </div>
                  </a>
                </div>
                <div class="date" v-show="isTextEmpty(item.text) && isTextEmpty(item.textTranslated)">{{ item.messageDate.substr(0, 16) }}</div>
                <div class="env" v-if="item.type === 1 && item.files.length">
                  {{ item.answerEnvText }}
                </div>
              </div>
              <div class="env" v-if="item.type === 1 && !item.files.length">
                {{ item.answerEnvText }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template v-if="!message.isBlockedBySeller">
      <div class="send-area-wrap" v-show="!loading">
        <div class="uploader-wrap" v-show="!loading && uploaderShow">
          <van-uploader class="uploader" v-model="fileList" :after-read="upload" ref="uploader" :max-size="25 * 1024 * 1024" preview-size="3.5rem" />
        </div>
        <div class="send-area">
          <div class="input">
            <van-field
                class="vant-textarea-hack normal"
                v-model="text"
                :rows="1"
                :border="true"
                type="textarea"
                :autosize="{maxHeight: 120, minHeight: 24,}"
                :placeholder="message.isBlocked ? message.blockedText : (language === 'en' ? '请输入英语' : '请输入西班牙语')"
                :disabled="message.isBlocked"
                @focus="onAnswerFocus"
                @blur="onAnswerBlur"
            />
          </div>
          <div class="attachment"
               :class="{'disabled': message.isBlocked}"
          >
            <van-icon name="photo-o" @click="switchUploader"/>
          </div>
          <van-button class="button" type="primary" @click="send" :disabled="sendDisabled || message.isBlocked" :loading="sendDisabled">发送</van-button>
        </div>
        <div class="space"></div>
        <div class="template-select float-box" @click="openPicker()" :class="{disabled: message.isBlocked}" >
          <img :src="require('@/assets/images/template-selelct-float.png')" alt="select">
        </div>
        <div class="language-select float-box" :class="{disabled: message.isBlocked}" @click="selectLanguageVisible=true">
          <img :src="require('@/assets/images/language-en.png')" alt="language" v-if="language === 'en'">
          <img :src="require('@/assets/images/language-es.png')" alt="language" v-if="language === 'es'">
          <img :src="require('@/assets/images/language-none.png')" alt="language" v-if="language === ''">
        </div>
        <div class="invoice-select float-box" @click="selectInvoiceVisible = true;" :class="{disabled: message.isBlocked}" >
          <img :src="require('@/assets/images/invoice-float.png')" alt="select">
        </div>
      </div>
    </template>

    <template v-if="message.isBlockedBySeller">
      <div class="guide-options-wrap">
        <div class="guide-options">
          <div class="tips">在买家回复前，您必须选择一种消息类型以继续发送新消息</div>
          <div class="guide-templates">
            <div
                class="option"
                v-for="v in allowGuideTemplates"
                :key="v.optionId"
                :class="{'current': v.optionId === currentTemplate.optionId}"
                @click="guideSubmitData.optionId = v.optionId;guideSubmitData.text='';"
            >
              {{ v.name }} ({{ v.isAutoText ? '可编辑' : '不可编辑' }})
            </div>
          </div>
        </div>
        <!--        <div class="close">
                  <van-icon name="cross" />
                </div>-->
      </div>
      <div class="send-area-wrap">
        <div class="send-area guide-wrap" v-show="!loading">
          <div class="input">
            <van-field
                class="vant-textarea-hack"
                v-model="guideSubmitData.text"
                :rows="1"
                :border="true"
                type="textarea"
                :autosize="{maxHeight: 120, minHeight: 24,}"
                :placeholder="currentTemplate.isAutoText ? '' : currentTemplate.placeholderText"
                :disabled="!currentTemplate.isAutoText"
                @focus.native="onAnswerFocus"
                @blur.native="onAnswerBlur"
            />
          </div>
          <div class="template-select" @click="openPicker()" v-show="currentTemplate.isAutoText">
            <img :src="require('@/assets/images/select-template.png')" alt="select">
          </div>
          <van-button class="button" type="primary" @click="sendGuideMessage" :disabled="sendDisabled">发送</van-button>
        </div>
        <div class="space"></div>
      </div>
    </template>
    <van-popup v-model="showQuestionTemplate" position="bottom" :style="{ height: '50%' }">
      <van-picker
          title="请选择对应的模板"
          show-toolbar
          :columns="allTemplatesTitles"
          @confirm="onTemplatesConfirm"
          @cancel="showQuestionTemplate = false"
      />
    </van-popup>
    <van-popup v-model="selectLanguageVisible" position="bottom" :style="{ height: '40%' }">
      <div class="language-select-panel">
        <div class="title">选择回复语言</div>
        <div class="item" @click="selectLanguage('es')">
          <div class="name">西班牙语</div>
          <div class="tips">
            <div class="line">回复内容将会直接发送</div>
          </div>
        </div>
        <div class="item" @click="selectLanguage('en')">
          <div class="name">英语</div>
          <div class="tips">
            <div class="line">原文会转换为西班牙语再发送，由 MercadoLibre 提供翻译支持</div>
            <div class="line">需由系统发出后再转译，无法实时预览翻译内容</div>
          </div>
        </div>
        <div class="close" @click="selectLanguageVisible = false">
          <van-icon name="cross" />
        </div>
      </div>
    </van-popup>
    <van-popup v-model="selectInvoiceVisible" position="bottom" :style="{ height: '40%' }">
      <div class="language-select-panel">
        <div class="title">发票（Invoice）</div>
        <div class="item disabled">
          <div class="name">预览Invoice</div>
          <div class="tips">
            <div class="line red">建议您发送前先预览当前Invoice信息</div>
            <div class="line">微信端无法预览，请移步PC端查看</div>
          </div>
        </div>
        <div class="item" @click="sendInvoice()">
          <div class="name">发送Invoice</div>
          <div class="tips">
            <div class="line">当前Invoice是根据用户当前售后的订单信息</div>
            <div class="line">由美客多自动生成的固定文件，不可修改</div>
          </div>
        </div>
        <div class="close" @click="selectInvoiceVisible = false">
          <van-icon name="cross" />
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import request from "@/utils/request";
import {ImagePreview, Loading} from "vant";

let timer = null;
export default {
  beforeRouteLeave(to, from, next) {
    if (timer) {
      clearInterval(timer);
    }
    next();
  },
  components: {

  },
  data() {
    return {
      loading: false,
      uploaderShow: false,
      fileList: [],
      chatList: [],
      message: {},
      text: '',
      sendDisabled: false,
      allGuideTemplates: [],
      guideSubmitData: {
        optionId: '',
        text: '',
      },
      showQuestionTemplate: false,
      allTemplates: [],
      selectTemplate: null,
      // 是否显示回复输入框的红字
      showTemplateTips: false,
      // 上次刷新时间
      lastRefreshTimestamp: null,
      // 最后对话时间
      lastMessageTimestamp: null,
      // 选择的语言
      language: '',
      // 显示选择语言的面板
      selectLanguageVisible: false,
      // 显示发票相关选项
      selectInvoiceVisible: false,
    }
  },
  computed: {
    // 用户信息
    userInfo: function () {
      return this.$store.state.userInfo;
    },
    // 当前信息能选择的模板
    allowGuideTemplates: function () {
      let allowGuideTemplates = this.allGuideTemplates;
      if (this.message && this.message.guidesOptionIds) {
        allowGuideTemplates = allowGuideTemplates.filter(v => this.message.guidesOptionIds.indexOf(v.optionId) >= 0);
      }
      return allowGuideTemplates;
    },
    // 当前选择的模板
    currentTemplate: function () {
      let currentTemplate = {};
      if (this.guideSubmitData.optionId) {
        currentTemplate = this.allGuideTemplates.find(v => v.optionId === this.guideSubmitData.optionId);
      }
      currentTemplate = currentTemplate ? currentTemplate : {};
      return currentTemplate;
    },
    // 全部标题
    allTemplatesTitles: function () {
      const allTemplatesTitles = [];
      this.allTemplates.forEach(item => {
        allTemplatesTitles.push(item.title);
      })
      return allTemplatesTitles;
    },
    // 复合文字
    complexText: {
      get: function () {
        if (!this.message.isBlockedBySeller) {
          return this.text;
        } else {
          return this.guideSubmitData.text;
        }
      },
      set: function (value) {
        if (!this.message.isBlockedBySeller) {
          this.text = value;
        } else {
          this.guideSubmitData.text = value;
        }
      },
    },
    // 全部聊天列表
    chatImages: function () {
      const images = [];
      this.chatList.forEach(item => {
        item.files.forEach(file => {
          if (this.isImageFile(file)) {
            images.push(file.file);
          }
        })
      })
      return images;
    },
    // 是否需要刷新
    isRefresh: function () {
      let isRefresh = false;
      if (this.lastRefreshTimestamp) {
        const nowTimestamp = Date.now() / 1000;
        isRefresh = nowTimestamp - this.lastMessageTimestamp < 30 * 86400 && nowTimestamp - this.lastRefreshTimestamp > 60;
      }
      return isRefresh;
    },
  },
  created() {
    this.loading = true;
    this.getLastRefreshTimestamp();
    this.getData(true);
    this.loopQueryData();
  },
  methods: {
    getData(down = false) {
      request({
        url: `/message/${this.$route.params.orderId}/${this.$route.params.oUserId}`,
      })
          .then(res => {
            this.loading = false;
            if (res.error === 0) {
              let update = false;
              if (this.chatList.length) {
                const oldLastTime = Date.parse(this.chatList[this.chatList.length - 1]['messageDate']);
                const lastTime = Date.parse(res.data[res.data.length - 1]['messageDate']);
                update = lastTime > oldLastTime;
              }
              const chatList = [];
              res.data.forEach(item => {
                item.textFormat = item.text.replace(/\n/g, '<br>');
                item.textTranslatedFormat = item.textTranslated.replace(/\n/g, '<br>');
                chatList.push(item);
              })
              this.chatList = chatList;
              this.message = res.info.message;

              const allGuideTemplates = res.info.allGuideTemplates;
              for (let i = 0; i < allGuideTemplates.length; i++) {
                if (allGuideTemplates[i].isAutoText) {
                  allGuideTemplates[i]['placeholderText'] = '可自由编辑，最多350个字符';
                } else {
                  let placeholderText = '不可编辑，将发送以下原文（仅西班牙语部分）';
                  placeholderText += "\n\n";
                  placeholderText += allGuideTemplates[i].esText;
                  placeholderText += "\n\n";
                  placeholderText += allGuideTemplates[i].zhText;
                  allGuideTemplates[i]['placeholderText'] = placeholderText;
                }
              }
              this.allGuideTemplates = allGuideTemplates;
              if (down && this.allGuideTemplates.length) {
                this.guideSubmitData.optionId = this.allGuideTemplates[0].optionId;
              }

              if (update || down) {
                setTimeout(() => {
                  this.$refs['box'].scrollTop = this.$refs['box'].scrollHeight;
                }, 600);
              }
              if (down) {
                this.allTemplates = res.info.templates;
              }
            } else {
              this.$router.push({
                name: 'index',
              });
              if (timer) {
                clearInterval(timer);
              }
              this.$errorMessage(res.message);
            }
          })
          .catch(error => {
            this.loading = false;
            console.log('请求聊天列表错误', error);
            this.$errorMessage('网络错误，请稍后再试');
          })
    },
    // 跳转商品页
    toGoodsOriginPage(goodsId) {
      window.open(  `https://articulo.mercadolibre.com.mx/${goodsId.substr(0,3)}-` + goodsId.substr(3), '_blank')
    },
    // 获取上次刷新时间
    getLastRefreshTimestamp() {
      request({
        url: `/message/${this.$route.params.orderId}/${this.$route.params.oUserId}/time`,
      })
          .then(res => {
            if (res.error === 0) {
              this.lastRefreshTimestamp = res.data.lastRefreshTimestamp;
              this.lastMessageTimestamp = res.data.lastMessageTimestamp;
            }
          })
          .catch(error => {
            console.log('请求刷新时间', error);
          })
    },
    // 上传
    upload(file) {
      file.status = 'uploading';
      file.message = '上传中...';

      const data = new FormData();
      data.append('file', file.file);

      request({
        url: `/message/${this.$route.params.orderId}/${this.$route.params.oUserId}/attachments`,
        method: 'post',
        data,
        config: {
          headers:{'Content-Type':'multipart/form-data'}
        },
      })
          .then(res => {
            if (res.error === 0) {
              file.url = res.info.imageUrl;
              file.imageId = res.info.imageId;
              file.status = 'done';
              file.message = '上传完成';
            } else {
              file.status = 'failed';
              file.message = '上传失败';
              this.$errorMessage(res.message);
            }
            this.clearFailedFiles();
          })
          .catch(error => {
            file.status = 'failed';
            file.message = '上传失败';
            console.log('上传文件失败', error);
            this.$errorMessage('网络错误');
            this.clearFailedFiles();
          })
    },
    // 切换上传界面
    switchUploader() {
      if (this.message.isBlocked && !this.message.isBlockedBySeller) {
        return false;
      }
      if (!this.uploaderShow) {
        this.uploaderShow = true;
        if (!this.fileList.length) {
          this.$refs['uploader'].chooseFile();
        }
      } else {
        this.uploaderShow = false;
      }
    },
    // 清除上传失败的文件
    clearFailedFiles() {
      for (let i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].status === 'failed') {
          this.fileList.splice(i, 1);
          break;
        }
      }
    },
    // 发送信息
    send() {
      // console.log( 'this.fileList', this.fileList);

      const attachments = [];
      this.fileList.forEach(item => {
        if (item.imageId) {
          attachments.push(item.imageId);
        }
      });

      if (!this.text && !attachments.length) {
        this.$message('请填写信息或上传附件');
        return false;
      }

      this.sendDisabled = true;

      // 语言选择
      let data;
      if (this.language === 'en') {
        data = {
          translateText: this.text,
          attachments: attachments,
        }
      } else {
        data = {
          text: this.text,
          attachments: attachments,
        }
      }
      request({
        url: `/message/${this.$route.params.orderId}/${this.$route.params.oUserId}/send`,
        method: 'post',
        data: data,
        timeout: 42000,
      })
          .then(res => {
            this.sendDisabled = false;
            if (res.error === 0) {
              if (this.fileList.length) {
                this.fileList = [];
              }
              this.uploaderShow = false;
              this.getData(true);
              this.text = '';
            } else {
              this.$errorMessage(res.message)
            }
          })
          .catch(error => {
            this.sendDisabled = false;
            this.$errorMessage('网络错误');
            console.log('发送信息错误', error);
          });
    },
    // 发送信息
    sendGuideMessage() {
      this.sendDisabled = true;
      request({
        url: `/message/guide/${this.$route.params.orderId}/${this.$route.params.oUserId}/send`,
        method: 'post',
        data: this.guideSubmitData,
        timeout: 42000,
      })
          .then(res => {
            this.sendDisabled = false;
            if (res.error === 0) {
              if (this.fileList.length) {
                this.fileList = [];
              }
              this.uploaderShow = false;
              this.getData(true);
              this.text = '';
            } else {
              this.$errorMessage(res.message)
            }
          })
          .catch(error => {
            this.sendDisabled = false;
            this.$errorMessage('网络错误');
            console.log('发送信息错误', error);
          })
    },
    // 轮询服务器是否有新的信息，暂定5秒执行一次
    loopQueryData() {
      if (timer) {
        clearInterval(timer);
      }

      timer = setInterval(() => {
        console.log('message_loop');
        if (this.$route.params.orderId && this.$route.params.oUserId) {
          this.getData();
        }
      }, 5000);
    },
    // 打开选择面板
    openPicker() {
      if (!this.message.isBlockedBySeller && this.message.isBlocked) {
        return false;
      }
      this.showQuestionTemplate = true;
    },
    // 选中特定模板
    onTemplatesConfirm(value, index) {
      this.showQuestionTemplate = false;
      if (this.allTemplates[index]) {
        this.selectTemplate = this.allTemplates[index];
        this.complexText = `${this.selectTemplate.content}`
        this.showTemplateTips = false;
      }
    },

    // 输入框获焦点时
    onAnswerFocus() {
      this.showTemplateTips = false;
      if (this.selectTemplate) {
        this.complexText = `${this.selectTemplate.content}`
      }
    },

    // 输入框失去焦点时
    onAnswerBlur() {
      if (this.selectTemplate) {
        this.showTemplateTips = false;
        if (this.complexText === this.selectTemplate.content) {
          this.complexText = `${this.selectTemplate.content}`
        } else {
          this.selectTemplate = null;
        }
      }
    },
    // 判断是否是图片
    isImageFile(file) {
      return ['jpg', 'png', 'gif'].indexOf(file.file.substr(-3)) >= 0 || ['jpeg', 'webp'].indexOf(file.file.substr(-4)) >= 0
    },
    // 判断聊天是否为空
    isTextEmpty(text) {
      return !text || text === '\n';
    },
    // 处理附件点击
    onClickFile(file, k = null, kk = null) {
      if (this.isImageFile(file)) {
        // 尝试定位到具体的图片
        let index = 0;
        if (k !== null && kk !== null) {
          let count = 0;
          this.chatList.forEach((item, i) => {
            item.files.forEach((file, ii) => {
              if (this.isImageFile(file)) {
                if (k === i && kk === ii) {
                  index = count;
                }
                count++;
              }
            });
          })
        } else {
          index = this.chatImages.find(v => v === file);
        }
        index = index ? index : 0;
        ImagePreview({
          images: this.chatImages,
          startPosition: index,
          closeable: true,
        });
      } else {
        window.open(file.file, '_blank');
      }
    },
    // 选择语言
    selectLanguage(language) {
      this.language = language;
      this.selectLanguageVisible = false;
      this.text = '';
    },
    showInvoice() {
      this.selectInvoiceVisible = false;
      let route = this.$router.resolve({
        name: 'message-invoice',
        params: {
          orderId: this.$route.params.orderId,
          oUserId: this.$route.params.oUserId,
        },
      });
      window.open(route.href, '_blank');
    },
    sendInvoice() {
      this.selectInvoiceVisible = false;
      this.loading = true;
      request({
        url: `message/invoice/${this.$route.params.orderId}/${this.$route.params.oUserId}/send`,
        method: 'post',
        timeout: 42000,
      })
          .then((res) => {
            if (res.error === 0) {
              this.getData(true);
            } else {
              this.$errorMessage(res.message);
            }
          })
          .finally(_ => {
            this.loading = false;
          })
    },
  }
}
</script>

<style lang="less" scoped>
.wrap{
  flex-direction: column;
  height: 100vh;
  .loading{
    padding: 150px 0 0;
  }
}
.header{
  font-size: 1.2rem;
  padding: .5rem 0;
  color: #000000;
  background-color: #f2f2f2;
  font-weight: bold;
  height: 1.6rem;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.content-wrap-box{
  padding: 0 0 2.8rem;
  height: 100vh;
  overflow-y: scroll;
  box-sizing: border-box;
  width: 100vw;
  overflow-x: hidden;
  &.show-uploader{
    padding: 2.8rem 0 7.6rem;
  }
}
.content-wrap{
  padding: .6rem;
  display: flex;
  flex-direction: column;
  .order{
    flex: 0 0 auto;
    border: 1px solid #aaaaaa;
    border-radius: .4rem;
    background: #f3f3f3;
    padding: 0 1rem;
    .other{
      display: flex;
      flex-direction: column;
      color: #aaaaaa;
      font-size: .8rem;
      //justify-content: space-between;
      padding: .4rem 0;
      border-bottom: 1px solid #eeeeee;
      align-items: flex-start;
      .right{
        text-align: left;
        .date{
          margin-left: 5px;
        }
        .shipping-status{
          margin-left: 5px;
        }
      }
    }
    .order-list{
      border-top: 1px solid #aaaaaa;
      border-bottom: 1px solid #aaaaaa;
      padding: .5rem 0 0;
      .item{
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 0 0 .5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        .goods-list{
          font-size: .8rem;
          .goods{
            display: flex;
            border-bottom: 1px solid #eeeeee;
            &:nth-last-child(1) {
              border-bottom: none;
            }
            .image{
              width: 3.5rem;
              height: 3.5rem;
              flex: 0 0 auto;
              margin-right: .5rem;
              img{
                width: 100%;
                height: 100%;
              }
            }
            .info{
              text-align: left;
              /*overflow: hidden;
              text-overflow: ellipsis;*/
              .title{
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                height: 1rem;
                line-height: 1rem;
              }
              .id{
                //padding: .2rem 0;
                .space{
                  margin: 0 3px;
                }
                .attr-name{
                  color: #aaaaaa;
                }
              }
              .num{
                padding: 0 0 0;
              }
            }
          }
        }
        .amount-wrap{
          font-size: .8rem;
          color: #aaaaaa;
          padding-left: 6px;
          .line{
            white-space: nowrap;
            text-align: left;
          }
        }
      }
    }
    .amount-data{
      display: flex;
      justify-content: space-between;
      font-size: .8rem;
      padding: .4rem 0;
      color: #aaaaaa;
    }
  }
  .chat-list{
    padding: .6rem 0 0;
    font-size: .9rem;
    flex: 1 1 auto;
    .item{
      display: flex;
      &.item1{
        flex-direction: row;
        justify-content: flex-start;
        .info{
          margin-left: .5rem;
        }
      }
      &.item2{
        flex-direction: row-reverse;
        .file-wrap{
          text-align: right;
        }
        .info{
          margin-right: .5rem;
        }
        .avatar{
          //padding-top: .3rem;
          height: 2.4rem;
          line-height: 2.4rem;
          .wx-avatar{
            width: 100%;
            height: 100%;
            img{
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .avatar{
        background-color: #d7d7d7;
        border-radius: 50%;
        font-size: 1.3rem;
        flex: 0 0 auto;
        width: 2.4rem;
        height: 2.4rem;
        line-height: 2.4rem;
        text-align: center;
        color: #666666;
        margin: .3rem 0 0;
        overflow: hidden;
      }
      .info{
        text-align: left;
        margin: .3rem 0;
        .box{
          border: none;
          padding: .3rem;
          border-radius: .3rem;
          background: #f3f3f3;
          margin-bottom: .3rem;
          /*.text{
            max-width: 60vw;
            white-space: pre-wrap;
          }*/
          &.blue{
            background: #d8e7ff !important;
          }
          &.box-text-translated{
            background: #deecec;
          }
          &:nth-last-child(2){
            margin-bottom: 0;
          }
        }
        .file-wrap{
          padding: .3rem 0;
          .image-file{
            img{
              max-width: 10rem;
              display: inline-block;
            }
          }
          .other-file{
            display: flex;
            align-items: center;
            color: #2c3e50;
            .icon{
              width: 2rem;
              margin-right: .3rem;
            }
          }
        }
        .ext{
          font-size: .72rem;
          color: #aaaaaa;
          padding: .2rem 0 0;
          text-align: left;
        }
        .date{
          font-size: .72rem;
          padding: .2rem 0 0;
        }
        .env {
          font-size: .7rem;
          color: #ADADAD;
        }
      }
    }
  }
}
.uploader-wrap{
  height: 3.5rem;
  width: 100%;
  text-align: left;
  background-color: #f2f2f2;
  padding: .5rem .5rem;
  border-bottom: 1px solid #ffffff;
  .uploader{

  }
}
.send-area-wrap{
  background-color: #f2f2f2;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  .space{
    height: constant(safe-area-inset-bottom);
    height: env(safe-area-inset-bottom);
  }
  .float-box{
    position: absolute;
    top: -42px;
    left: 10px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    //border: 1px solid #d7d7d7;
    box-sizing: border-box;
    &.disabled{
      opacity: .5;
    }
    &.template-select{
      left: 10px;
    }
    &.language-select{
      left: 58px;
    }
    &.invoice-select{
      left: 104px;
    }
    img{
      width: 100%;
      height: 100%;
    }
  }

}
.send-area{
  display: flex;
  align-items: flex-end;
  padding: .3rem .5rem .6rem;
  //height: 3.2rem;
  font-size: .9rem;
  box-sizing: border-box;

  width: 100%;
  .input{
    width: 100%;
    flex: 1 1 auto;
    line-height: 1.5;
    //padding: 0 4px;
    textarea{
      box-sizing: border-box;
      line-height: 1.5;
      border: none;
      border-radius: .4rem;
      width: 100%;
      padding: 5px 4px;
    }
  }
  .template-select{
    flex: 0 0 auto;
    margin: 0 .4rem;
    width: 1.8rem;
    height: 1.8rem;
    &.disabled{
      opacity: .5;
    }
    img{
      width: 100%;
      height: 100%;
    }
  }
  .attachment{
    flex: 0 0 auto;
    margin: .3rem .5rem 0 .5rem;
    font-size: 2rem;
    line-height: 2.8rem;
    width: 2rem;
    height: 2rem;
    &.disabled{
      opacity: .5;
    }
  }
  .button{
    flex: 0 0 auto;
    width: 5rem;
    margin: .2rem .3rem 0;
    padding: 0 .2rem;
    height: 1.8rem;
  }
}

.guide-options-wrap{
  position: fixed;
  bottom: 3.2rem;
  left: 0;
  width: 100%;
  padding: 30px 0 20px;
  background-color:rgba(255, 255, 255, 0.8);
  .guide-options{
    .tips{
      font-size: .9rem;
    }
    .guide-templates{
      padding: 20px 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .option{
        margin-bottom: 20px;
        padding: 5px 0;
        width: 90%;
        border: 1px solid #d7d7d7;
        border-radius: 8px;
        cursor: pointer;
        &.current{
          border: 1px solid #d8e7ff;
          background-color: #d8e7ff;
        }
      }
    }
  }
  .close{
    position: absolute;
    right: 20px;
    top: 5px;
  }
}
.language-select-panel{
  padding: 12px 0 0;
  .title{
    font-size: 18px;
    color: #000000;
    font-weight: bold;
    height: 8vh;
  }
  .item{
    border-top: 2px solid #f2f2f2;
    height: 14vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .name{
      font-size: 22px;
      color: #ff0200;
      font-weight: bold;
    }
    .tips{
      font-size: 14px;
      color: #aaaaaa;
      .line.red{
        color: #ff0200;
      }
    }
    &.disabled{
      background: #F2F2F2;
    }
  }
  .close{
    position: absolute;
    right: 20px;
    top: 12px;
    font-size: 22px;
  }
}

</style>

<style lang="less">
.input{
  .vant-textarea-hack{
    &.van-field{
      padding: 5px 4px;
      .van-field__label{
        display: none;
      }
    }
    &.normal{
      .van-field__body{
        textarea::-webkit-input-placeholder { /* WebKit, Blink, Edge */
          color : red;
        }
        textarea:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
          color : red;
        }
        textarea::-moz-placeholder { /* Mozilla Firefox 19+ */
          color : red;
        }
        textarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color : red;
        }
        textarea::-ms-input-placeholder { /* Microsoft Edge */
          color : red;
        }
      }
    }
  }
}
</style>
